import React, { FunctionComponent } from 'react';
import { ShippingProgressStep } from '../../../__generated__/graphql-client-types';
import { CheckIcon, HomeIcon, ContractIcon, Package2Icon, LocalShippingIcon } from '../../svg/icons.component';
import {
	circled,
	icon,
	lastStep,
	line,
	lineGradient,
	homepageDashboardCircled,
	processingStep,
	lastStepWidth
} from './shipment-progress-bar.css';

export type ShipmentProgressBarProps = {
	currentStep: ShippingProgressStep | 'PROCESSING' | 'IN_TRANSIT' | 'RECEIVED';
	isHomepageDashboardVersion?: boolean;
	computeLineColorClass?: (index, currentStepIndex) => string;
};

export const DefaultShipmentProgressBar: FunctionComponent<ShipmentProgressBarProps> = ({ currentStep, computeLineColorClass }) => {
	const steps = [
		{ key: 'ORDERED', label: 'Ordered', icon: ContractIcon },
		{ key: 'PREPARING', label: 'Preparing for Shipment', icon: Package2Icon },
		{ key: 'SHIPPED', label: 'Shipped', icon: LocalShippingIcon },
		{ key: 'DELIVERED', label: 'Delivered', icon: HomeIcon }
	];

	const currentStepIndex = steps.findIndex((step) => step.key === currentStep);
	return (
		<>
			{steps.map((step, index) => (
				<div key={step.key} className={index < steps.length - 1 ? 'w-25 flex-auto' : 'w2 flex-none'}>
					<step.icon className={`f2 ${icon} ${index <= currentStepIndex ? 'theme-primary' : 'theme-tertiary'}`} />

					<div className="mt1 mb2 flex items-center">
						<CheckIcon
							className={`${circled} pa1 b--solid bw1 ${
								index <= currentStepIndex ? 'theme-primary b--theme-primary' : 'b--theme-tertiary theme-white'
							}`}
						/>
						{index < steps.length - 1 && (
							<span
								className={`dib flex-auto ${line} ${
									computeLineColorClass ? computeLineColorClass(index, currentStepIndex) : ''
								}`}
							/>
						)}
					</div>
					<div
						/* eslint-disable-next-line sonarjs/no-nested-template-literals */
						className={`b theme-primary nowrap ${currentStepIndex === steps.length - 1 ? `relative ${lastStep}` : ''}`}
						data-automation="order-status">
						{currentStepIndex === index ? steps[currentStepIndex].label : ''}
					</div>
				</div>
			))}
		</>
	);
};

export const HomePageDashboardShipmentProgressBar: FunctionComponent<ShipmentProgressBarProps> = ({
	currentStep,
	computeLineColorClass
}) => {
	const steps = [
		{ key: 'ORDERED', label: 'Received', icon: ContractIcon },
		{ key: 'PREPARING', label: 'Processing', icon: Package2Icon },
		{ key: 'SHIPPED', label: 'In Transit', icon: LocalShippingIcon },
		{ key: 'DELIVERED', label: 'Delivered', icon: HomeIcon }
	];

	const currentStepIndex = steps.findIndex((step) => step.key === currentStep);

	return (
		<>
			{steps.map((step, index) => (
				<div key={step.key} className={index < steps.length - 1 ? 'w-25 flex-auto' : `${lastStepWidth} flex-none`}>
					<div className={`mt1 mb2 flex items-center`}>
						<div
							className={`${homepageDashboardCircled} ${
								index <= currentStepIndex ? 'theme-primary' : 'theme-tertiary'
							} flex justify-center items-center`}>
							<step.icon className={`f3`} />
						</div>
						{index < steps.length - 1 && (
							<span
								className={`dib flex-auto ${line} ${
									computeLineColorClass ? computeLineColorClass(index, currentStepIndex) : ''
								}`}
							/>
						)}
					</div>
					<div
						/* eslint-disable-next-line sonarjs/no-nested-template-literals */
						className={`theme-primary nowrap f7 ${step.key === 'PROCESSING' ? `relative ${processingStep}` : ''} ${
							index <= currentStepIndex ? 'theme-primary' : 'theme-tertiary'
						}`}>
						{step.label}
					</div>
				</div>
			))}
		</>
	);
};

export const ShipmentProgressBar: FunctionComponent<ShipmentProgressBarProps> = ({ currentStep, isHomepageDashboardVersion = false }) => {
	const computeLineColorClass = (index, currentStepIndex) => {
		let lineColorClass = 'bg-theme-tertiary';
		if (index === currentStepIndex) {
			lineColorClass = lineGradient;
		} else if (index < currentStepIndex) {
			lineColorClass = 'bg-theme-primary';
		}
		return lineColorClass;
	};

	return (
		<div className="flex w-100">
			{isHomepageDashboardVersion ? (
				<HomePageDashboardShipmentProgressBar currentStep={currentStep} computeLineColorClass={computeLineColorClass} />
			) : (
				<DefaultShipmentProgressBar currentStep={currentStep} computeLineColorClass={computeLineColorClass} />
			)}
		</div>
	);
};
